import classNames from 'classnames';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';
import Alert from '../../../components/bootstrap/Alert';
import Button from '../../../components/bootstrap/Button';
import Spinner from '../../../components/bootstrap/Spinner';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Icon from '../../../components/icon/Icon';

import { useLoginMutation } from '../../../features/auth/authApi';
import useDarkMode from '../../../hooks/useDarkMode';
import Page from '../../../layout/Page/Page';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';

import LoginImg from '../../../assets/img/login-car.jpg';
import logo from '../../../assets/logos/ariana_logo.png';
import './login.css';

const Login = () => {
	const { accessToken } = useSelector((state) => state.auth);

	const [login, { isLoading, error: responseError }] = useLoginMutation();

	const { darkModeStatus } = useDarkMode();
	const [error, setError] = useState('');
	const [passType, setPassType] = useState(false);

	useEffect(() => {
		if (responseError?.data) {
			setError(responseError.data?.error);
		}
	}, [responseError]);

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			loginUsername: '',
			loginPassword: '',
		},
		validate: (values) => {
			const errors = {};

			if (!values.loginUsername) {
				errors.loginUsername = 'Username is Required';
			}

			if (!values.loginPassword) {
				errors.loginPassword = 'Password is Required';
			}
			return errors;
		},
		onSubmit: (values) => {
			setError('');

			login({
				email: values.loginUsername,
				password: values.loginPassword,
			});
		},
	});

	/** Submit by Enter */
	const handleKeyDown = (e) => {
		if (e.key === 'Enter') {
			formik.handleSubmit();
		}
	};

	return accessToken ? (
		<Navigate to='/' />
	) : (
		<PageWrapper isProtected={false} title={'Login'} className='login-wrapper'>
			<Page className='p-0'>
				<div className='h-100 d-flex align-items-center justify-content-center'>
					<div className='card card-login'>
						<div className='card-body'>
							<div className='row h-100  align-items-stretch g-0 '>
								<div className='col-md-6 col-xl-8'>
									<div className='img-wrapper'>
										<img className='img-fluid' src={LoginImg} alt='' />
									</div>
								</div>
								<div className='col-md-6 col-xl-4'>
									<div className='login-content-wrapper'>
										<div className='login-content'>
											<div className='text-center mb-5'>
												<Link
													to='/auth-pages/login'
													className={classNames(
														'text-decoration-none  fw-bold display-2',
														{
															'text-dark': !darkModeStatus,
															'text-light': darkModeStatus,
														},
													)}>
													<img
														src={logo}
														alt='Logo'
														style={{ maxWidth: '200px' }}
													/>
												</Link>
											</div>
											<div
												className={classNames('rounded-3', {
													'bg-l10-dark': !darkModeStatus,
													'bg-dark': darkModeStatus,
												})}></div>

											<div
												className='text-center h1 fw-bold my-5'
												style={{ color: '#1B4B51' }}>
												Let's Get <br /> You start
											</div>
											{/* <div className='text-center h4  mb-5 text-white'>
											Sign in to continue!
										</div> */}

											<form className='row g-4' style={{ color: '#1B4B51' }}>
												<>
													{error && (
														<Alert
															isLight
															color='danger'
															icon='Lock'
															isDismissible>
															<div className='row'>
																<div className='col-12'>
																	<strong> {error}</strong>
																</div>
															</div>
														</Alert>
													)}
													<div className='col-12'>
														<FormGroup
															id='loginUsername'
															isFloating
															label='Your email or username'
															style={{
																marginBottom: '20px',
															}}>
															<Input
																className='my_input'
																value={formik.values.loginUsername}
																isTouched={
																	formik.touched.loginUsername
																}
																invalidFeedback={
																	formik.errors.loginUsername
																}
																isValid={formik.isValid}
																onChange={formik.handleChange}
															/>
														</FormGroup>
														<div className='input-wrapper-icon'>
															<FormGroup
																id='loginPassword'
																isFloating
																className=''
																label='Password'>
																<Input
																	className='my_input'
																	type={
																		passType
																			? 'text'
																			: 'password'
																	}
																	value={
																		formik.values.loginPassword
																	}
																	isTouched={
																		formik.touched.loginPassword
																	}
																	invalidFeedback={
																		formik.errors.loginPassword
																	}
																	isValid={formik.isValid}
																	onChange={formik.handleChange}
																	onKeyDown={handleKeyDown}
																/>
															</FormGroup>
															<span
																className='icon-hodlder'
																onClick={() =>
																	setPassType(!passType)
																}>
																<Icon
																	size='lg'
																	icon={
																		passType ? 'Eye' : 'EyeOff'
																	}
																/>
															</span>
														</div>
													</div>

													<div className='col-12'>
														<Button
															color='light'
															className='w-100 py-3 border-rounded mt-5'
															onClick={formik.handleSubmit}
															isDisable={isLoading}>
															Login
															{isLoading && (
																<Spinner isSmall inButton isGrow />
															)}
														</Button>
													</div>
												</>
											</form>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};
// Login.propTypes = {
// 	isSignUp: PropTypes.bool,
// };
// Login.defaultProps = {
// 	isSignUp: false,
// };

export default Login;
