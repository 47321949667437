export const ROLES = Object.freeze({
  MASTER_ADMIN: 1,
  SUPER_ADMIN: 2,
  LOCATION_ADMIN: 3,
  CUSTOMER: 4,
  EMPLOYEE: 5,
  ACCOUNT: 6,
  ADMIN: 7,
  LOCATION_VIEW_ADMIN: 8,
  LOCATION_RESTRICTED_ADMIN: 9
});